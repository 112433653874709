import React, { useEffect, useState } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { getFooterHTML, getSystemName } from '../helpers';

const Footer = () => {
  const systemName = getSystemName();
  const [footer, setFooter] = useState(getFooterHTML());
  let remainCheckTimes = 5;

  const loadFooter = () => {
    let footer_html = localStorage.getItem('footer_html');
    if (footer_html) {
      setFooter(footer_html);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainCheckTimes <= 0) {
        clearInterval(timer);
        return;
      }
      remainCheckTimes--;
      loadFooter();
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const customFooterStyle = {
    backgroundColor: '#f1f1f1',
    padding: '20px',
    textAlign: 'center',
  };

  const linkStyle = {
    color: 'black',
    textDecoration: 'none',
  };

  const openChat = () => {
    window.chaport.q('open');
    return false;
  };
  

  return (
    <Segment vertical>
      <Container textAlign='center'>

        {footer ? (
          <div
            className='custom-footer'
            dangerouslySetInnerHTML={{ __html: footer }}
          ></div>
        ) : (
          <div className='custom-footer' style={customFooterStyle}>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <li><a href="https://cost.openkey.cloud/" target="_blank" rel="noopener noreferrer" style={linkStyle}>成本计算器</a></li>
              <li><a href="https://docs.51buygpt.net/about" target="_blank" rel="noopener noreferrer" style={linkStyle}>关于我们</a></li>
              {/* <li><a href="https://openkey.cloud/pricing" style={linkStyle}>价格</a></li> */}
              {/* <li><a href="https://openkey.cloud/docs" style={linkStyle}>API文档</a></li> */}
              <li><a href="#" style={linkStyle} onClick={openChat}>技术支持</a></li>
              <li><a href="https://openkey.cloud/terms" style={linkStyle}>使用条款与条件</a></li>
              <li><a href="https://status.openkey.cloud/" target="_blank" rel="noopener noreferrer" style={linkStyle}>系统状态</a></li>
              <li><a href="https://gptocean.com/" target="_blank" rel="noopener noreferrer" style={linkStyle}>社区论坛</a></li>
              <li><a href="https://t.me/s/niuyeyegpt" target="_blank" rel="noopener noreferrer" style={linkStyle}>Telegram</a></li>
              
            </ul>
          </div>
        )}
      </Container>
    </Segment>
  );
};

export default Footer;